import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { color } from "./colors"
import Container from "../atomic/partials/container"
import Email from "../images/email.inline.svg"
import Phone from "../images/phone.inline.svg"
import Fax from "../images/fax.inline.svg"

const CustomHeader = styled.header`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${color.secondary};
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: height 0.3s ease;

  @media (min-width: 992px) {
    height: 34px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      display: none;
    }
  }

  &.alt {
    height: 50px;

    @media (min-width: 992px) {
      height: 34px;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      & > span {
        display: inline;
      }
    }

    nav {
      transform: translateY(-10px);
    }
  }

  svg {
    height: 14px;
    margin-right: 8px;

    path {
      fill: ${color.primary};
    }
  }
`

const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 288px;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  background-color: ${color.secondary};
  transition: height 0.3s ease, transform 0.3s ease;
  overflow: hidden;

  @media (min-width: 992px) {
    position: static;
    background-color: transparent;
    background-image: none;
    height: auto;
    overflow: visible;
  }

  &.hidden {
    height: 0px;

    @media (min-width: 992px) {
      height: auto;
    }
  }
`;

const Hamburger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 45px;
  padding: 5px;
  position: relative;
  background: transparent;
  border: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 1;

  @media (min-width: 992px) {
    display: none;
  }

  &:focus {
    outline-color: #fff;
  }

  span {
    display: block;
    height: 6px;
    width: 35px;
    background-color: ${color.black};
    border-radius: 2px;
    position: relative;
    transition: background-color 0.3s ease;
  }

  &.active {
    justify-content: center;
    padding: 2px;

    span {
      position: absolute;
      transition: transform 0.3s ease;

      &:nth-child(1) {
        top: calc(50% - 3px);
        transform: rotate(45deg);
        transform-origin: center;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        bottom: calc(50% - 3px);
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
`

const ContactBlock = styled.div`
  display: none;
  justify-content: flex-end;
  flex-grow: 1;
  max-width: 600px;
  color: ${color.black};
  font-size: 12px;
  font-height: 1;
  font-weight: 700;

  @media (min-width: 992px) {
    display: flex;
  }

  div:not(:last-child) {
    margin-right: 8px;

    @media (min-width: 1200px) {
      margin-right: 16px;
    }
  }

  div {
    display: flex;
    white-space: nowrap;

    span {
      margin-right: 4px;

      @media (max-width: 1199px) {
        display: none;
      }
    }

    svg {
      @media (max-width: 1199px) {
        margin-right: 6px;
      }
    }
  }
`

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;

  @media (min-width: 992px) {
    display: none;
  }

  li {
    margin-bottom: 0;

    a {
      color: ${color.black};
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      transition: color 0.3s ease;

      @media (min-width: 992px) {
        font-size: 12px;
        line-height: 1;
      }

      &:hover {
        color: ${color.primary};
      }
    }
  }

  li:not(:last-child) {
    margin-bottom: 16px;

    @media (min-width: 992px) {
      margin-right: 8px;
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      margin-right: 16px;
    }
  }
`

const Header = ({ siteTitle }) => {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false)

  useEffect(() => {
    const hero = document.querySelector(".top-overlay")
    const header = document.querySelector(".header")

    if (hero) {
      const heroSize = (hero.offsetHeight - 110) / 2

      if (document.documentElement.scrollTop > heroSize) {
        header.classList.add("alt")
      } else {
        header.classList.remove("alt")
      }

      window.onscroll = () => {
        if (document.documentElement.scrollTop > heroSize) {
          header.classList.add("alt")
        } else {
          header.classList.remove("alt")
        }
      }
    } else {
      header.classList.add("alt")
    }
  })

  const handleClick = e => {
    e.target.closest("button").classList.toggle("active")
    setHamburgerIsActive(!hamburgerIsActive)
  }

  const handleMenuItemClick = e => {
    e.target.closest("ul").parentElement.classList.remove("active")
    e.target.closest("ul").parentElement.previousSibling.classList.remove("active")
    setHamburgerIsActive(!hamburgerIsActive)
  }

  return (
    <CustomHeader className="header">
      <Container position="static" positionLG="relative">
        <span>Taras Północ</span>
        <Hamburger
          className="hamburger" 
          onClick={e => handleClick(e)}
        >
          <span />
          <span />
          <span />
        </Hamburger>
        <Navigation className={hamburgerIsActive ? "active" : "hidden"}>
          <NavList>
            <li>
              <Link to="/wladze" onClick={e => handleMenuItemClick(e)}>WŁADZE</Link>
            </li>
            <li>
              <Link to="/akty-prawne" onClick={e => handleMenuItemClick(e)}>AKTY PRAWNE</Link>
            </li>
            <li>
              <Link to="/przetargi" onClick={e => handleMenuItemClick(e)}>PRZETARGI</Link>
            </li>
            <li>
              <Link to="/ogloszenia" onClick={e => handleMenuItemClick(e)}>OGŁOSZENIA</Link>
            </li>
            <li>
              <Link to="/aktualnosci" onClick={e => handleMenuItemClick(e)}>AKTUALNOŚCI</Link>
            </li>
            <li>
              <Link to="/inwestycje" onClick={e => handleMenuItemClick(e)}>INWESTYCJE</Link>
            </li>
          </NavList>
        </Navigation>
        <ContactBlock className="contactBlock">
          <div>
            <Email />
            <span>Email:</span> smtaras@op.pl
          </div>
          <div>
            <Phone />
            <span>Telefon:</span> +48 091 416 45 70
          </div>
          <div>
            <Fax />
            <span>Faks:</span> +48 091 416 26 26
          </div>
        </ContactBlock>
      </Container>
    </CustomHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
