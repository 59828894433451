import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Wrapper = styled.div`
width: 100%;
max-width: 568px;
padding-left: 15px;
padding-right: 15px;
margin: 0 auto;
position: ${props => (props.position ? props.position : "relative")};

  @media (min-width: 769px) {
    max-width: 768px;
    position: ${props => (props.positionMD ? props.positionMD : "")};
  }

  @media (min-width: 992px) {
    max-width: 900px;
    position: ${props => (props.positionLG ? props.positionLG : "")};
  }

  @media (min-width: 1200px) {
    max-width: 100%;
    // padding-left: 15px;
    // padding-right: 15px;
  }

  @media (min-width: ${props => props.width}px) {
    max-width: ${props => props.width}px;
  }
`

const Container = ({ children, style, width, className, id, position, positionMD, positionLG }) => (
  <Wrapper style={style} width={width} className={className} id={id} position={position} positionMD={positionMD} positionLG={positionLG}>
    {children}
  </Wrapper>
)

export default Container

Container.propTypes = {
  width: PropTypes.number,
}

Container.defaultProps = {
  width: 1200,
}
